export const getNavConfig = () => {
  const enterpriseCode =
    window.localStorage.getItem('enterprise') + '' ||
    window.localStorage.getItem('enterprise');
  const isShow = false
  return [{
      icon: require('/src/assets/home.svg'),
      name: '首页',
      path: '/system/index/home',
      action: 'homeOne',
    },

    {
      icon: require('/src/assets/user.svg'),
      icons: require('/src/assets/user_show.svg'),
      name: '人资管理',
      path: '/system/user',
      action: 'userOne',
      children: [{
          // icon: require('/src/assets/user01.svg'),
          name: '当前用户',
          path: '/system/user/detail?isShow=' + isShow,
          action: 'customerUser'
        },
        {
          // icon: require('/src/assets/user02.svg'),
          name: '当前企业',
          path: '/system/enterprise/detail?code=' + enterpriseCode + '&isShow=' + isShow,
          action: 'enterpriseMenu'
        },
        {
          // icon: require('/src/assets/user04.svg'),
          name: '员工列表',
          path: '/system/user/list',
          action: 'userMenu'
        },


      ]
    },
    {
      icon: require('/src/assets/contract.svg'),
      icons: require('/src/assets/contract_show.svg'),
      name: '合同管理',
      path: '/system/contract/list',
      action: 'contractOne',
      children: [{
          // icon: require('/src/assets/contract01.svg'),
          name: '合同列表',
          path: '/system/contract/list',
          action: 'myContract'
        },
        {
          // icon: require('/src/assets/contract02.svg'),
          name: '合同模板列表',
          path: '/system/contractTemplate/list',
          action: 'contractTemplateMenu'
        }
      ]
    },
    {
      icon: require('/src/assets/invoice.svg'),
      icons: require('/src/assets/invoice_show.svg'),
      name: '企业管理',
      path: '/system/project/index',
      action: 'customerMenu',
      children: [{
        // icon: require('/src/assets/contract01.svg'),
        name: '发薪企业',
        path: '/system/project/index',
        action: 'serviceLists'
      }, {
        name: '用工企业',
        path: '/system/employer/index',
        action: 'employerMenu'
        },
      ]
    },
    {
      icon: require('/src/assets/package.svg'),
      icons: require('/src/assets/package_show.svg'),
      name: '服务包管理',
      path: '/system/service/index',
      action: 'serviceMenu',
      children: [{
        // icon: require('/src/assets/contract01.svg'),
        name: '服务包列表',
        path: '/system/service/index',
        action: 'serviceLists'
      }, {
        name: '工资单模板',
        path: '/system/service/serviceTemplate',
        action: 'serviceTemplates'
        },
        {
          name: '参数配置',
          path: '/system/service/configuration'  
        },
    ]
    },
    {
      icon: require('/src/assets/invoice.svg'),
      name: '项目管理',
      path: '/system/enterpriseProject/index',
      action: 'enterpriseProjectMenu',
    },
    {
      icon: require('/src/assets/invoice.svg'),
      name: '发放明细',
      path: '/system/grant/index',
      action: 'grantMenu',
    },
    {
      icon: require('/src/assets/invoice.svg'),
      name: '发票列表',
      path: '/system/invoice/index',
      action: 'invoiceMenu',
    },
    {
      icon: require('/src/assets/package.svg'),
      icons: require('/src/assets/package_show.svg'),
      name: '工资包列表',
      path: '/system/task/list',
      action: 'taskOne',
      // children: [{
      //     // icon: require("/src/assets/package01.svg"),
      //     name: "工资包列表",
      //     path: "/system/task/list",
      //     action: "taskList"
      //   },
      //   {
      //     // icon: require("/src/assets/package02.svg"),
      //     name: "工资单模板管理",
      //     path: "/system/task/taskTemplate",
      //     action: "taskTemplateOne"
      //   }
      // ]
    },
    {
      icon: require('/src/assets/finance.svg'),
      icons: require('/src/assets/finance_show.svg'),
      name: '财务功能',
      path: '/system/trade',
      action: 'tradeOne',
      children: [{
          name: '交易管理',
          path: '/system/trade/list',
          action: 'tradeMenu'
        },
        {
          name: '提现管理',
          path: '/system/withdraw/list',
          action: 'withdrawalMenu'
        },
        {
          name: '查看充值记录',
          path: '/system/trade/recharge',
          action: 'listConsumingRecordsMenu'
        }
      ]
    },
    {
      icon: require('/src/assets/agent.svg'),
      icons: require('/src/assets/agent_show.svg'),
      name: '权限管理',
      path: '/system/roleManagement',
      action: 'authorityManagement',
      children: [{
          icon: require('/src/assets/content.svg'),
          name: '角色管理',
          path: '/system/roleManagement/List',
          action: 'roleManagement'
        },

        {
          icon: require('/src/assets/package.svg'),
          icons: require('/src/assets/package_show.svg'),
          name: '资源管理',
          path: '/system/setAccess?type=resource',
          action: 'resourceEnter'
        },
        {
          icon: require('/src/assets/content.svg'),
          name: '导出/导入',
          path: '/system/roleManagement/exportResources',
          action: 'authStatistics'
        }
      ]
    },
  ];
};